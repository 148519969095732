import React from "react";
import "./services.css";
import { FaCheck } from "react-icons/fa";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Intuitive Interfaces: Designing user-friendly and accessible interfaces.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Responsive Design: Ensuring compatibility across all devices.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>User-Centered Solutions: Focused on creating engaging user experiences.</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Full Stack Expertise: Building dynamic, responsive websites.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Performance Optimization: Enhancing speed and efficiency.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Custom Web Solutions: Tailoring sites to specific business needs.</p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Engaging Content: Crafting compelling digital narratives.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>SEO-Optimized Writing: Boosting online visibility and reach.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Multimedia Integration: Leveraging videos, infographics, and more.</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  );
};

export default Services;
