import React from "react";
import "./about.css";
import ME from "../../assets/IMG_6802.JPG";
import { FaAward } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { IoIosBook } from "react-icons/io";

function About() {
  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years of Working</small>
            </article>{" "}
            <article className="about__card">
              <FaUsers className="about__icon" />
              <h5>Clients</h5>
              <small>100+ Worldwide</small>
            </article>{" "}
            <article className="about__card">
              <IoIosBook className="about__icon" />
              <h5>Projects</h5>
              <small>20+</small>
            </article>
          </div>
          <p>
            I am Mart Gjepali, a dedicated and versatile programmer with over
            three years of professional experience. My expertise is bolstered by
            a strong academic foundation in Business Informatics, having earned
            my Bachelor's degree from Luarasi University.
          </p>
          <a href="#contact" className="btn btn-priamry">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
}

export default About;
