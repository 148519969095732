import React from "react";
import "./portfolio.css";
import softsage from "../../assets/softsage.PNG";
import oneAir from "../../assets/one-air.PNG";
import gymRegistration from "../../assets/gym-registration.jpg";
import bankApp from "../../assets/bank-app.png";
import algorithm from "../../assets/algorithm.png";
import fingerCounter from "../../assets/finger-counter.jpg";

const data = [
  {
    id: 1,
    image: softsage,
    title: "SoftSage",
    github: "https://github.com/martgjepali/softsage_website",
    demo: "softsage.co",
  },
  {
    id: 2,
    image: oneAir,
    title: "One Air",
    github: "https://github.com/martgjepali?tab=repositories",
    demo: "http://api-one-air.lafe6113.odns.fr/en/index.html",
  },
  {
    id: 3,
    image: gymRegistration,
    title: "Gym Registration Platform",
    github: "https://github.com/martgjepali/gym_registration_app",
    demo: "https://github.com/martgjepali/gym_registration_app",
  },
  {
    id: 4,
    image: bankApp,
    title: "Modern Bank App",
    github: "https://github.com/martgjepali/modern_bank_app",
    demo: "https://github.com/martgjepali/modern_bank_app",
  },
  {
    id: 5,
    image: algorithm,
    title: "Sorting Algorithm Visualizer",
    github: "https://github.com/martgjepali/Sorting-Algorithm-Visualizer",
    demo: "https://github.com/martgjepali/Sorting-Algorithm-Visualizer",
  },
  {
    id: 6,
    image: fingerCounter,
    title: "Finger Counter With Python",
    github: "https://github.com/martgjepali/Finger-Counter",
    demo: "https://github.com/martgjepali/Finger-Counter",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} alt={title} className="portfolio__item">
              <div className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt="" />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a
                    href={github}
                    className="btn"
                    target="_blank"
                  >
                    Github
                  </a>
                  <a href={demo} className="btn btn-primary" target="_blank">
                    Live Demo
                  </a>
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
