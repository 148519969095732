import React from "react";
import "./testimonials.css";
import avatar from "../../assets/avatar-male.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: avatar,
    name: "SoftSage",
    review:
      "Absolutely outstanding experience! The guy's expertise and commitment to excellence were evident in every interaction. He not only delivered high-quality results but also demonstrated exceptional customer service. His ability to understand and adapt to our needs made a significant difference. Highly recommend him for any project!",
  },
  {
    avatar: avatar,
    name: "Kristi Lame",
    review:
      "I'm thoroughly impressed with the professionalism and creativity shown by this guy. He consistently went above and beyond, providing innovative solutions that perfectly aligned with our vision. His communication and project management skills were top-notch, making the entire process seamless and enjoyable. Truly a five-star experience!",
  },
  {
    avatar: avatar,
    name: "Tirana Express",
    review:
      "From start to finish, working with this guy was a pleasure. He exhibited a deep understanding of our project goals and executed them flawlessly. His attention to detail and commitment to delivering exceptional work within tight deadlines was remarkable. He is, without doubt, a go-to partner for any business looking for quality and reliability.",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from Clients</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonials">
              <div className="client__avatar">
                <img src={avatar} alt="" />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
