import React from "react";
import "./nav.css";
import { IoHomeSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa6";
import { RiCustomerService2Line } from "react-icons/ri";
import { IoIosChatboxes } from "react-icons/io";
import { useState } from "react";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
      <a
        className={activeNav === "#" ? "active" : ""}
        onClick={() => setActiveNav("#")}
        href="#"
      >
        <IoHomeSharp />
      </a>
      <a
        className={activeNav === "#about" ? "active" : ""}
        href="#about"
        onClick={() => setActiveNav("#about")}
      >
        <FaUser />
      </a>
      <a className={activeNav === "#experience" ? "active" : ""} onClick={() => setActiveNav("#experience")} href="#experience">
        <FaBookOpen />
      </a>
      <a className={activeNav === "#services" ? "active" : ""} onClick={() => setActiveNav("#services")} href="#services">
        <RiCustomerService2Line />
      </a>
      <a className={activeNav === "#contact" ? "active" : ""} onClick={() => setActiveNav("#contact")} href="#contact">
        <IoIosChatboxes />
      </a>
    </nav>
  );
};

export default Nav;
