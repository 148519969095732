import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const HeraderSocials = () => {
  return (
    <div className="header__socials">
      <a
        href="https://www.linkedin.com/in/mart-gjepali-25939019b/"
        target="_blank"
      >
        <FaLinkedin />
      </a>
      <a href="https://github.com/martgjepali?tab=repositories" target="_blank">
        <FaGithub />
      </a>
      <a href="https://www.instagram.com" target="_blank">
        <FaInstagram />
      </a>
    </div>
  );
};

export default HeraderSocials;
